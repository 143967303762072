import React, { useState, useEffect, Suspense, lazy } from 'react';
import RequestAccessForm from './components/RequestAccessForm';
import HeroSection from './components/sections/HeroSection';
import BenefitsSection from './components/sections/BenefitsSection';
import ExitPopup from './components/ExitPopup';
import { SpotsProvider } from './components/SpotsContext';

// Lazy load all components
const LiveNotifications = lazy(() => import('./components/LiveNotifications'));
const TestimonialWall = lazy(() => import('./components/TestimonialWall'));
const SocialProofSection = lazy(() => import('./components/sections/SocialProofSection'));
const GuaranteeSection = lazy(() => import('./components/sections/GuaranteeSection'));
const PricingSection = lazy(() => import('./components/sections/PricingSection'));
const FAQSection = lazy(() => import('./components/sections/FAQSection'));
const FooterSection = lazy(() => import('./components/sections/FooterSection'));
const ComparisonSection = lazy(() => import('./components/sections/ComparisonSection'));
const TrustedBySection = lazy(() => import('./components/sections/TrustedBySection'));
const AdvancedFeaturesSection = lazy(() => import('./components/sections/AdvancedFeaturesSection'));

// Create a simple loading component
const SectionLoader = () => (
  <div className="py-12 flex justify-center items-center">
    <div className="w-8 h-8 border-2 border-purple-500 border-t-transparent rounded-full animate-spin"></div>
  </div>
);

const App = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasClickedForm, setHasClickedForm] = useState(false);
  const initializationPromise = React.useRef(null);

  useEffect(() => {
    const initializeApp = async () => {
      if (!initializationPromise.current) {
        initializationPromise.current = (async () => {
          try {
            // Initialize analytics first
            const analyticsModule = await import('./utils/analytics');
            await analyticsModule.initializeAnalytics();

            // Then track the page visit
            const trackingModule = await import('./utils/tracking');
            await trackingModule.trackPageVisit();
          } catch (error) {
            console.error('Error initializing app:', error);
          }
        })();
      }
      return initializationPromise.current;
    };

    if (typeof window !== 'undefined') {
      initializeApp();
    }
  }, []);

  const handleRequestAccess = () => {
    setIsModalOpen(true);
  };

  const handleFormClick = () => {
    setHasClickedForm(true);
  };

  return (
    <SpotsProvider>
      <div className="min-h-screen bg-black text-white">
        <ExitPopup hasClickedForm={hasClickedForm} />
        <RequestAccessForm 
          isOpen={isModalOpen} 
          onClose={() => setIsModalOpen(false)} 
          onFormClick={handleFormClick}
        />
        <HeroSection onRequestAccess={handleRequestAccess} />
        <BenefitsSection onRequestAccess={handleRequestAccess} />
        <Suspense fallback={<SectionLoader />}>
          <LiveNotifications />
        </Suspense>
        <Suspense fallback={<SectionLoader />}>
          <TrustedBySection />
        </Suspense>
        <Suspense fallback={<SectionLoader />}>
          <ComparisonSection onRequestAccess={handleRequestAccess} />
        </Suspense>
        <Suspense fallback={<SectionLoader />}>
          <SocialProofSection />
        </Suspense>
        <Suspense fallback={<SectionLoader />}>
          <AdvancedFeaturesSection onRequestAccess={handleRequestAccess} />
        </Suspense>
        <Suspense fallback={<SectionLoader />}>
          <TestimonialWall />
        </Suspense>
        <Suspense fallback={<SectionLoader />}>
          <GuaranteeSection onRequestAccess={handleRequestAccess} />
        </Suspense>
        <Suspense fallback={<SectionLoader />}>
          <PricingSection onRequestAccess={handleRequestAccess} />
        </Suspense>
        <Suspense fallback={<SectionLoader />}>
          <FAQSection />
        </Suspense>
        <Suspense fallback={<SectionLoader />}>
          <FooterSection onRequestAccess={handleRequestAccess} />
        </Suspense>

        <style jsx global>{`
          html {
            overflow-x: hidden;
            width: 100%;
            margin: 0;
            padding: 0;
          }

          body {
            overflow-x: hidden;
            width: 100%;
            margin: 0;
            padding: 0;
            -webkit-overflow-scrolling: touch;
          }

          * {
            box-sizing: border-box;
          }

          @media screen and (max-width: 375px) {
            .overflow-x-auto {
              -webkit-overflow-scrolling: touch;
              -ms-overflow-style: -ms-autohiding-scrollbar;
            }
          }

          @keyframes infinite-scroll {
            from { transform: translateX(0); }
            to { transform: translateX(-100%); }
          }

          .animate-infinite-scroll {
            animation: infinite-scroll 60s linear infinite;
          }

          .animate-infinite-scroll-reverse {
            animation: infinite-scroll 60s linear infinite reverse;
          }

          .animate-infinite-scroll:hover,
          .animate-infinite-scroll-reverse:hover {
            animation-play-state: paused;
          }

          @keyframes pulse-slow {
            0%, 100% { opacity: 0.4; }
            50% { opacity: 0.1; }
          }

          .animate-pulse-slow {
            animation: pulse-slow 3s ease-in-out infinite;
          }

          @media (max-width: 640px) {
            .testimonial-grid {
              grid-template-columns: 1fr;
            }
            
            .hero-text {
              font-size: 2rem;
            }
            
            .section-padding {
              padding: 3rem 1rem;
            }
          }

          @media (min-width: 641px) and (max-width: 1024px) {
            .testimonial-grid {
              grid-template-columns: repeat(2, 1fr);
            }
            
            .hero-text {
              font-size: 2.5rem;
            }
            
            .section-padding {
              padding: 4rem 2rem;
            }
          }

          @media (min-width: 1025px) {
            .testimonial-grid {
              grid-template-columns: repeat(3, 1fr);
            }
            
            .hero-text {
              font-size: 3rem;
            }
            
            .section-padding {
              padding: 5rem 3rem;
            }
          }

          .hover-glow:hover {
            box-shadow: 0 0 20px rgba(139, 92, 246, 0.3);
          }

          .hover-scale:hover {
            transform: scale(1.05);
          }

          .fade-in {
            opacity: 0;
            animation: fadeIn 0.5s ease-in forwards;
          }

          @keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
          }

          ::-webkit-scrollbar {
            width: 8px;
          }

          ::-webkit-scrollbar-track {
            background: rgba(139, 92, 246, 0.1);
          }

          ::-webkit-scrollbar-thumb {
            background: rgba(139, 92, 246, 0.3);
            border-radius: 4px;
          }

          ::-webkit-scrollbar-thumb:hover {
            background: rgba(139, 92, 246, 0.5);
          }

          @supports (-webkit-overflow-scrolling: touch) {
            .text-transparent {
              -webkit-background-clip: text !important;
              background-clip: text !important;
              -webkit-text-fill-color: transparent !important;
            }
            
            .bg-gradient-to-r {
              -webkit-transform: translateZ(0);
              transform: translateZ(0);
            }
            
            .bg-clip-text {
              -webkit-background-clip: text !important;
              background-clip: text !important;
            }
          }

          .gradient-fix {
            position: relative;
            z-index: 1;
            -webkit-background-clip: text !important;
            background-clip: text !important;
            -webkit-text-fill-color: transparent !important;
          }

          .gradient-text {
            transform: translateZ(0);
            backface-visibility: hidden;
            perspective: 1000;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }
        `}</style>
      </div>
    </SpotsProvider>
  );
};

export default App;