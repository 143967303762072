import React, { useState } from 'react';
import { ArrowRight, X } from 'lucide-react';

const RequestAccessForm = ({ isOpen, onClose, onFormClick }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: ''
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  // Form validation
  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = 'Name is required';
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Please enter a valid email';
    }
    if (!formData.phone) {
      newErrors.phone = 'Phone number is required';
    } else if (!/^\+?[\d\s-]{10,}$/.test(formData.phone)) {
      newErrors.phone = 'Please enter a valid phone number';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Form submission handler
  const handleButtonClick = async (e) => {
    e.preventDefault();
    // Call the callback to update parent state immediately
    onFormClick();
    
    if (validateForm()) {
      setIsSubmitting(true);
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const trackingNumber = urlParams.get('future') || 'direct';

        const response = await fetch('https://appguard.site:3000/submit-landing-form', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...formData,
            trackingNumber
          }),
        });

        if (!response.ok) {
          throw new Error('Form submission failed');
        }

        setIsSuccess(true);
        setTimeout(() => {
          setIsSuccess(false);
          setFormData({ name: '', email: '', phone: '' });
          onClose();
        }, 2000);
      } catch (error) {
        console.error('Error submitting form:', error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      {/* Backdrop */}
      <div 
        className="fixed inset-0 bg-black/90 backdrop-blur-sm"
        onClick={onClose}
      />
      
      {/* Modal */}
      <div className="flex min-h-full items-center justify-center p-4">
        <div className="relative w-[85%] sm:w-full max-w-lg">
          {/* Gradient border effect */}
          <div className="absolute -inset-1">
            <div className="w-full h-full mx-auto rotate-180 opacity-30 blur-xl filter">
              <div className="absolute inset-0 bg-gradient-to-r from-purple-600 via-blue-600 to-purple-600 rounded-full animate-pulse" />
            </div>
          </div>
          
          {/* Modal content */}
          <div className="relative bg-black rounded-xl border border-purple-500/20">
            <button
              onClick={onClose}
              className="absolute right-4 top-4 text-gray-400 hover:text-white transition-colors"
            >
              <X className="w-5 h-5" />
            </button>

            <div className="p-8">
              <div className="text-center mb-8">
                <h3 className="text-3xl font-bold bg-gradient-to-r from-purple-400 to-blue-500 bg-clip-text text-transparent mb-3 gradient-fix">
                  Request Access to CopyTalist AI
                </h3>
                
                <p className="text-base text-gray-300">
                  Enter your details below to secure your spot
                </p>
              </div>

              <form onSubmit={handleButtonClick} className="space-y-6">
                {/* Name Input */}
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Your Name*"
                    value={formData.name}
                    onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                    className={`w-full bg-purple-900/20 border ${errors.name ? 'border-red-500' : 'border-purple-500/30'} rounded-lg px-4 py-3 text-base text-white placeholder-gray-400 focus:outline-none focus:border-purple-500 transition-colors`}
                  />
                  {errors.name && (
                    <p className="absolute -bottom-5 left-0 text-red-500 text-xs">{errors.name}</p>
                  )}
                </div>

                {/* Email Input */}
                <div className="relative">
                  <input
                    type="email"
                    placeholder="Your Best Email*"
                    value={formData.email}
                    onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
                    className={`w-full bg-purple-900/20 border ${errors.email ? 'border-red-500' : 'border-purple-500/30'} rounded-lg px-4 py-3 text-base text-white placeholder-gray-400 focus:outline-none focus:border-purple-500 transition-colors`}
                  />
                  {errors.email && (
                    <p className="absolute -bottom-5 left-0 text-red-500 text-xs">{errors.email}</p>
                  )}
                </div>

                {/* Phone Input */}
                <div className="relative">
                  <input
                    type="tel"
                    placeholder="Your Phone Number*"
                    value={formData.phone}
                    onChange={(e) => setFormData(prev => ({ ...prev, phone: e.target.value }))}
                    className={`w-full bg-purple-900/20 border ${errors.phone ? 'border-red-500' : 'border-purple-500/30'} rounded-lg px-4 py-3 text-base text-white placeholder-gray-400 focus:outline-none focus:border-purple-500 transition-colors`}
                  />
                  {errors.phone && (
                    <p className="absolute -bottom-5 left-0 text-red-500 text-xs">{errors.phone}</p>
                  )}
                </div>

                {/* Submit Button */}
                <div className="pt-8">
                  <button
					type="submit"
					disabled={isSubmitting}
					style={{
					  background: 'linear-gradient(to bottom right, #EE121A, #F54A4C)'
					}}
					className="w-full text-white font-bold py-3 sm:py-4 px-4 sm:px-8 rounded-lg transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center gap-1 sm:gap-2 text-[11px] sm:text-base whitespace-nowrap hover:opacity-90"
				  >
					{isSubmitting ? 'Processing...' : isSuccess ? 'Success! 🎉' : (
					  <>
						REQUEST FREE ACCESS NOW
						<ArrowRight className="w-3 h-3 sm:w-5 sm:h-5 group-hover:translate-x-1 transition-transform flex-shrink-0" />
					  </>
					)}
				  </button>
                  <p className="text-center text-xs text-gray-300 mt-3">
                    😇 (We'd rather high-five a cactus than send you spam)
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestAccessForm;