import React, { createContext, useContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';

const SpotsContext = createContext();
// Obscured cookie name using base64 encoding of 'remaining_spots' + random string
const SPOTS_COOKIE_NAME = '_ct_v1_' + btoa('rs_' + '8x4k9w').replace(/=/g, '');
const INITIAL_SPOTS = 34;

// Utility functions for base64 encoding/decoding
const encodeSpots = (spots) => {
  return btoa(spots.toString());
};

const decodeSpots = (encodedSpots) => {
  try {
    const decoded = atob(encodedSpots);
    const spots = parseInt(decoded, 10);
    return !isNaN(spots) && spots >= 0 ? spots : INITIAL_SPOTS;
  } catch {
    return INITIAL_SPOTS;
  }
};

export const SpotsProvider = ({ children }) => {
  // Initialize with cookie value or default
  const [spotsLeft, setSpotsLeft] = useState(() => {
    const savedSpots = Cookies.get(SPOTS_COOKIE_NAME);
    if (savedSpots) {
      return decodeSpots(savedSpots);
    }
    return INITIAL_SPOTS;
  });

  // Save to cookie whenever spots change
  useEffect(() => {
    const encodedSpots = encodeSpots(spotsLeft);
    Cookies.set(SPOTS_COOKIE_NAME, encodedSpots, { expires: 365 }); // Expires in 1 year
  }, [spotsLeft]);

  const decreaseSpots = () => {
    setSpotsLeft(prev => Math.max(0, prev - 1));
  };
  
  const canShowLiveNotifications = spotsLeft > 5;

  return (
    <SpotsContext.Provider value={{ 
      spotsLeft, 
      decreaseSpots,
      canShowLiveNotifications 
    }}>
      {children}
    </SpotsContext.Provider>
  );
};

export const useSpots = () => useContext(SpotsContext);