import React, { useState, useEffect } from 'react';
import { ArrowRight, Star } from 'lucide-react';
import { useSpots } from '../../components/SpotsContext';

const CreditCardIcon = () => (
  <div className="relative inline-flex">
    <svg 
      viewBox="0 0 24 24" 
      width="100%" 
      height="100%" 
      className="w-4 h-4 xs:w-4 xs:h-4 sm:w-5 sm:h-5"
    >
      <rect x="2" y="5" width="20" height="14" rx="2" className="fill-purple-400"/>
      <rect x="2" y="9" width="20" height="3" className="fill-yellow-400"/>
      {/* Slash */}
      <line 
        x1="3" 
        y1="3" 
        x2="21" 
        y2="21" 
        stroke="currentColor" 
        strokeWidth="2.5"
        strokeLinecap="round"
        className="stroke-red-500"
      />
    </svg>
  </div>
);

const BenefitsSection = ({ onRequestAccess }) => {
  const { spotsLeft } = useSpots();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  return (
    <div className="bg-black text-white py-12 sm:py-24">
      <div className="max-w-6xl mx-auto px-6 sm:px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 sm:gap-12 items-center mb-12 sm:mb-32">
          {/* Mobile stats section - Complete Border Version */}
          <div className="md:hidden">
            <div className="relative">
              {/* Premium backdrop glow */}
              <div className="absolute inset-0 -z-10">
                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-3/4 h-[120%] bg-gradient-to-b from-purple-500/20 via-blue-500/10 to-transparent blur-[100px] rounded-full"></div>
              </div>

              {/* Stats Layout */}
              <div className="relative space-y-4 py-6">
                {/* 40%+ Stat */}
                <div className="relative bg-gradient-to-r from-purple-500/50 to-blue-500/50 p-[1px] rounded-xl">
                  <div className="bg-black rounded-xl px-6 py-5 flex flex-col">
                    <div className="text-[2.75rem] font-bold bg-gradient-to-r from-purple-400 to-blue-400 bg-clip-text text-transparent leading-none">40%+</div>
                    <div className="text-blue-200 text-sm font-medium mt-2">Higher Conversion Rate (Guaranteed!)</div>
                  </div>
                </div>

                {/* 10x Stat */}
                <div className="relative bg-gradient-to-r from-purple-500/50 to-blue-500/50 p-[1px] rounded-xl">
                  <div className="bg-black rounded-xl px-6 py-5 flex flex-col">
                    <div className="text-[2.75rem] font-bold bg-gradient-to-r from-purple-400 to-blue-400 bg-clip-text text-transparent leading-none">10x</div>
                    <div className="text-blue-200 text-sm font-medium mt-2">Cheaper Than One Copy From a Copywriter</div>
                  </div>
                </div>

                {/* 47s Stat */}
                <div className="relative bg-gradient-to-r from-purple-500/50 to-blue-500/50 p-[1px] rounded-xl">
                  <div className="bg-black rounded-xl px-6 py-5 flex flex-col">
                    <div className="text-[2.75rem] font-bold bg-gradient-to-r from-purple-400 to-blue-400 bg-clip-text text-transparent leading-none">47s</div>
                    <div className="text-blue-200 text-sm font-medium mt-2">To Generate What Takes Agencies 2 Weeks</div>
                  </div>
                </div>
              </div>
            </div>
          </div>



          {/* Text content */}
          <div className="space-y-3 px-2 relative z-20">
            <h2 className="text-2xl xs:text-3xl sm:text-4xl md:text-6xl font-bold mb-2 sm:mb-5 leading-tight">
              <span className="bg-gradient-to-r from-purple-400 to-blue-500 bg-clip-text text-transparent gradient-fix inline-block pb-3">
                Generate Copy That Delivers 2X, 4X, Even 10X ROAS… In 47 Seconds Flat And Boost Your Conversions Through The Roof
              </span>
            </h2>
            
            <p className="text-sm sm:text-xl text-gray-300 mb-3 sm:mb-8 leading-relaxed">
              While you're wrestling with ChatGPT prompts for DAYS trying to get copy that couldn't sell heaters in Alaska... Our AI just analyzed $4.8B in winning campaigns and is ready to print money faster than the Fed.
            </p>
          </div>

          {/* Desktop stats with graph */}
          <div className="relative hidden md:block">
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-3/4 h-3/4 bg-purple-500/10 blur-[120px] rounded-full"></div>
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-3/4 h-3/4 bg-blue-500/10 blur-[120px] rounded-full transform rotate-45"></div>
            
            <div className="relative grid grid-cols-2 gap-4 sm:gap-8">
              <div className="bg-gradient-to-br from-purple-900/30 to-blue-900/30 rounded-full aspect-square flex flex-col items-center justify-center p-4 sm:p-8 border border-purple-500/20 hover:border-purple-500/40 transition-all duration-300 hover:scale-105 hover:shadow-[0_0_30px_-5px_rgba(168,85,247,0.4)]">
                <div className="text-4xl sm:text-6xl font-bold bg-gradient-to-br from-purple-400 to-blue-500 bg-clip-text text-transparent mb-2 gradient-fix">40%+</div>
                <p className="text-gray-400 text-center text-sm sm:text-base">Higher Conversion Rate (Guaranteed!)</p>
              </div>
              
              <div className="bg-gradient-to-br from-purple-900/30 to-blue-900/30 rounded-full aspect-square flex flex-col items-center justify-center p-4 sm:p-8 border border-purple-500/20 hover:border-purple-500/40 transition-all duration-300 hover:scale-105 hover:shadow-[0_0_30px_-5px_rgba(168,85,247,0.4)]">
                <div className="text-4xl sm:text-6xl font-bold bg-gradient-to-br from-purple-400 to-blue-500 bg-clip-text text-transparent mb-2 gradient-fix">10x</div>
                <p className="text-gray-400 text-center text-sm sm:text-base">Cheaper Than One Copy From a Copywriter</p>
              </div>
              
              <div className="bg-gradient-to-br from-purple-900/30 to-blue-900/30 rounded-full aspect-square flex flex-col items-center justify-center p-4 sm:p-8 border border-purple-500/20 hover:border-purple-500/40 transition-all duration-300 hover:scale-105 hover:shadow-[0_0_30px_-5px_rgba(168,85,247,0.4)] col-span-2 sm:col-span-1 sm:translate-x-1/2">
                <div className="text-4xl sm:text-6xl font-bold bg-gradient-to-br from-purple-400 to-blue-500 bg-clip-text text-transparent mb-2 gradient-fix">47s</div>
                <p className="text-gray-400 text-center text-sm sm:text-base">To Generate What Takes Agencies 2 Weeks</p>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 sm:gap-20 items-center mb-12 sm:mb-32">
          <div className="relative">
            <svg className="w-full h-[300px] sm:h-[400px]" viewBox="0 0 500 400">
              <defs>
                <linearGradient id="lineGradient" x1="0" y1="1" x2="1" y2="0">
                  <stop offset="0%" stopColor="#8B5CF6" />
                  <stop offset="50%" stopColor="#6366F1" />
                  <stop offset="100%" stopColor="#3B82F6" />
                </linearGradient>
                <linearGradient id="areaGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#8B5CF6" stopOpacity="0.1" />
                  <stop offset="100%" stopColor="#3B82F6" stopOpacity="0.02" />
                </linearGradient>
              </defs>

              <g>
                {[0, 40, 80, 120, 160].map((tick) => {
                  const y = 350 - (tick * 1.8);
                  return (
                    <g key={tick}>
                      <line 
                        x1="40" 
                        y1={y} 
                        x2="460" 
                        y2={y} 
                        stroke="#1F2937" 
                        strokeWidth="1" 
                        strokeDasharray="4 6"
                      />
                    </g>
                  );
                })}
              </g>

              <line 
                x1="40" 
                y1="350" 
                x2="460" 
                y2="350" 
                stroke="#374151" 
                strokeWidth="2"
              />

              <path
                d="M40 350 Q 180 320, 300 180 T 460 50"
                fill="none"
                stroke="url(#lineGradient)"
                strokeWidth="3"
              />

              <path
                d="M40 350 Q 180 320, 300 180 T 460 50 L 460 350 Z"
                fill="url(#areaGradient)"
              />

              <g>
                <circle cx="40" cy="350" r="6" fill="#8B5CF6" />
                <circle cx="40" cy="350" r="10" fill="#8B5CF6" fillOpacity="0.2" />
                <text x="40" y="380" fill="#9CA3AF" fontSize="13" textAnchor="middle" className="font-medium">Starting</text>
              </g>

              <g>
                <circle cx="460" cy="50" r="6" fill="#3B82F6" />
                <circle cx="460" cy="50" r="10" fill="#3B82F6" fillOpacity="0.2" />
                <text 
                  className="mobile-svg-text hidden sm:block" 
                  x="460" 
                  y="380" 
                  fill="#9CA3AF" 
                  fontSize="13" 
                  textAnchor="middle"
                >
                  With CopyTalist
                </text>
                <text 
                  className="mobile-svg-text sm:hidden" 
                  x="400" 
                  y="380" 
                  fill="#9CA3AF" 
                  fontSize="13" 
                  textAnchor="start"
                >
                  With CopyTalist
                </text>
                <text x="460" y="25" fill="#fff" fontSize="14" fontWeight="bold" textAnchor="middle">167%</text>
              </g>

              <text x="40" y="30" fill="#fff" fontSize="16" fontWeight="bold">Average Conversion Rate Increase</text>
            </svg>
          </div>

          <div className="space-y-6 px-2">
            <div className="group">
              <h3 className="text-2xl xs:text-3xl sm:text-3xl font-bold mb-2 leading-tight">
                <span className="bg-gradient-to-r from-purple-400 to-blue-500 bg-clip-text text-transparent gradient-fix">
                  Do 100x More With 10x Less - While Your Competition's Still Writing Their First Headline
                </span>
                <span className="text-white"> 💸</span>
              </h3>
              <p className="text-sm sm:text-xl text-gray-300 leading-relaxed">
                Stop wasting hours staring at a blank screen. CopyTalist spits out money-printing copy faster than crypto bros lose their life savings.
              </p>
            </div>
            
            <div className="group">
              <h3 className="text-2xl xs:text-3xl sm:text-3xl font-bold mb-2 leading-tight gradient-fix">
                <span className="bg-gradient-to-r from-purple-400 to-blue-500 bg-clip-text text-transparent gradient-fix">
                  $4.8B Worth Of Marketing Secrets Living In Your Laptop 24/7
                </span>
                <span className="text-white"> 🧠</span>
              </h3>
              <p className="text-sm sm:text-xl text-gray-300 leading-relaxed">
                Stop guessing what works - start with what's already proven to convert like crazy.
              </p>
            </div>
          </div>
        </div>

        <div className="text-center -mx-4 px-6 md:mx-0 md:px-0">
          {/* Social Proof */}
          <div className="inline-flex items-center justify-center gap-1.5 xs:gap-2 sm:gap-2.5 mb-3 sm:mb-4">
            <div className="flex items-center gap-0.5 xs:gap-1">
              {[...Array(5)].map((_, i) => (
                <Star
                  key={i}
                  className={`w-3 h-3 xs:w-3.5 xs:h-3.5 sm:w-4 sm:h-4 fill-yellow-400 text-yellow-400`}
                />
              ))}
            </div>
            <span className="text-yellow-400 font-medium text-xs xs:text-sm sm:text-base">4.8</span>
            <span className="text-gray-300 text-xs xs:text-sm sm:text-base">rated by</span>
            <span className="text-blue-400 font-medium text-xs xs:text-sm sm:text-base">600+</span>
            <span className="text-gray-300 text-xs xs:text-sm sm:text-base">users</span>
          </div>

          <button 
            onClick={onRequestAccess} 
            style={{
              background: 'linear-gradient(to bottom right, #EE121A, #F54A4C)'
            }}
            className="w-full md:w-auto px-6 sm:px-8 py-3 sm:py-4 rounded-xl text-base xs:text-lg sm:text-xl md:text-2xl font-bold hover:opacity-90 transition-all flex items-center justify-center gap-2 md:mx-auto group whitespace-nowrap relative z-20"
          >
            REQUEST YOUR FREE ACCESS NOW 
            <ArrowRight className="w-4 h-4 sm:w-5 sm:h-5 group-hover:translate-x-1 transition-transform shrink-0" />
          </button>
            
          <p className="flex justify-center items-center flex-nowrap text-gray-300 text-[10px] xs:text-xs sm:text-base mt-4 px-2 sm:px-4">
            <span className="text-yellow-400 mr-0.5 xs:mr-1">⚡️</span>
            <span>December Offer: Only {isMobile ? '34' : spotsLeft} spots left</span>
            <span className="mx-1 xs:mx-2">•</span>
            <span className="flex items-center gap-1">
              <CreditCardIcon />
              <span className="whitespace-nowrap">No credit card required</span>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default BenefitsSection;