import React, { useState, useEffect, useRef } from 'react';
import { X } from 'lucide-react';

const ExitPopup = ({ hasClickedForm }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hasShown, setHasShown] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const lastY = useRef(0);
  const mouseMovements = useRef([]);
  
  useEffect(() => {
    // Wait 1.5 seconds before initializing
    const timer = setTimeout(() => {
      setIsInitialized(true);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!isInitialized) return;

    const handleMouseMove = (e) => {
  const currentY = e.clientY;
  
  // Track last few movements for more accurate direction detection
  mouseMovements.current.push(currentY);
  if (mouseMovements.current.length > 3) {
    mouseMovements.current.shift();
  }

  // Calculate if movement is consistently upward
  const isMovingUp = mouseMovements.current.length > 2 && 
    mouseMovements.current.every((y, i) => 
      i === 0 || y < mouseMovements.current[i - 1]
    );
  
  // If mouse is in top 2% of the screen and moving upward
  if (currentY < window.innerHeight * 0.02 && isMovingUp && !hasShown && !hasClickedForm) {
    setIsOpen(true);
    setHasShown(true);
  }
  
  lastY.current = currentY;
};

    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      mouseMovements.current = [];
    };
  }, [hasShown, hasClickedForm, isInitialized]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/90 backdrop-blur-sm z-[100] flex items-center justify-center p-4">
      <div className="w-[min(800px,95vw)] bg-gradient-to-b from-black to-[#0A0A16] rounded-2xl relative overflow-hidden">
        {/* Animated Glow Effect */}
        <div className="absolute inset-0 bg-gradient-to-r from-purple-600/10 via-blue-500/10 to-purple-600/10 blur-3xl pointer-events-none"></div>
        
        {/* Progress Bar */}
        <div className="w-full h-1.5 bg-gradient-to-r from-purple-900/20 via-blue-900/20 to-purple-900/20">
          <div className="w-1/2 h-full bg-gradient-to-r from-purple-500 via-blue-400 to-purple-500 animate-pulse"></div>
        </div>

        <div className="p-10 sm:p-10 p-6 relative">
          {/* Close Button */}
          <button 
            onClick={() => setIsOpen(false)}
            className="absolute right-4 top-4 text-gray-400 hover:text-white transition-colors"
          >
            <X size={24} className="hover:rotate-90 transition-transform duration-200" />
          </button>

          {/* Main Content */}
          <div className="text-center relative">
            <div className="absolute inset-0 bg-gradient-to-b from-purple-500/5 via-transparent to-transparent blur-xl pointer-events-none"></div>
            
            <h2 className="text-2xl sm:text-3xl md:text-4xl font-black mb-4 sm:mb-6 text-transparent bg-clip-text bg-gradient-to-r from-purple-400 via-blue-300 to-purple-400 leading-tight animate-gradient">
              HOLD UP!<br/>
              You're One Click Away From<br/>
              Changing Your Entire Business Forever
            </h2>

            <p className="text-base sm:text-lg text-gray-300/90 mb-6 sm:mb-8">
              Get FREE access to our AI trained on $4.8B+ in winning campaigns<br className="hidden sm:block"/>
              Plus 5 premium bonuses (Worth $600)
            </p>

            {/* GIF Container with Premium Styling */}
            <div className="relative w-full h-[200px] sm:h-[400px] mb-6 sm:mb-8 rounded-xl overflow-hidden bg-gradient-to-b from-purple-900/20 to-blue-900/20 border border-purple-500/20">
              <div className="absolute inset-0 bg-gradient-to-r from-purple-500/10 via-transparent to-purple-500/10 animate-pulse"></div>
              <img 
                src="/wait.gif" 
                alt="Wait!" 
                className="w-full h-full object-cover"
              />
            </div>

            {/* CTA Button with Enhanced Styling */}
				<button 
				  onClick={() => setIsOpen(false)}
				  style={{
					background: 'linear-gradient(to bottom right, #EE121A, #F54A4C)'
				  }}
				  className="group relative w-full py-4 sm:py-5 px-6 sm:px-8 text-white text-lg sm:text-xl font-bold rounded-xl transition-all duration-300 hover:scale-[1.02] hover:opacity-90 overflow-hidden"
				>
				  <span className="relative">F*CK IT, SHOW ME THE MONEY GLITCH</span>
				</button>
          </div>
        </div>

        {/* Bottom Gradient Border */}
        <div className="absolute bottom-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-purple-500/50 to-transparent"></div>
      </div>

      <style jsx>{`
        @keyframes gradient {
          0% { background-position: 0% 50%; }
          50% { background-position: 100% 50%; }
          100% { background-position: 0% 50%; }
        }
        .animate-gradient {
          background-size: 200% auto;
          animation: gradient 4s linear infinite;
        }
      `}</style>
    </div>
  );
};

export default ExitPopup;