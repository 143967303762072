// src/components/GradientHeader.js
import React, { useState, useEffect } from 'react';

const GradientHeader = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);
  
  useEffect(() => {
    // Set initial value
    setIsMobile(window.innerWidth < 768);
    
    // Add resize listener
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <span className={`
      bg-clip-text text-transparent gradient-fix
      ${isMobile 
        ? 'bg-[linear-gradient(90deg,#A78BFA_0%,#3B82F6_100%)]' 
        : 'bg-gradient-to-r from-purple-400 to-blue-500'
      }
    `}>
      {children}
    </span>
  );
};

export default GradientHeader;